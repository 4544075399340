<script setup lang="ts">
import type { Locale } from 'lc-services/types'

const { authenticated } = useAuth()
const { headerMobile } = useHeaderMobile()
const { headerTheme, pageTheme, setTemporaryTheme } = useHeaderNavigation()
const { isMobile, isSmallScreen } = useBreakpoint()
const { locale } = useI18n<unknown, Locale>()
const { locked } = useLockBody()
const { properties } = useUserHouses()
const {
  headerHandler,
  headerSearchHandler,
  height,
  setMenuOpen,
  setSearchMobileOpen,
  setSignInChoiceOpen,
} = useHeaderV2()
const { trackEvent, pageType } = useTracking()
const localePath = useLocalePath()
const route = useRoute()

const affix = ref(false)
const affixAnimate = useState('affixAnimate', () => false)
const lastScrollTop = ref(0)
const scrollTop = ref(0)

const heightClass = computed(() => `h-[${height.value}px]`)
const nameRoute = computed(() => String(route.name ?? '').slice(0, -5))

const accountLink = computed(() => {
  return properties.value.length
    ? localePath({
        name: 'myaccount-property-name-rentals',
        params: { name: properties.value[0].slug[locale.value] },
      })
    : localePath('myaccount-bookings')
})

const menuMobileIsOpen = computed(() => headerMobile.value.menuIsOpen)
const hasWhiteColorCondition = computed(
  () => !menuMobileIsOpen.value && headerTheme.value === 'transparent',
)
const colorIcon = computed(() =>
  hasWhiteColorCondition.value ? 'white' : 'black',
)
const colorClassIcon = computed(() =>
  hasWhiteColorCondition.value ? 'text-white' : 'text-gray-700',
)

const affixHandle = () => {
  if (locked.value) return

  scrollTop.value = window.pageYOffset || document.documentElement.scrollTop

  affixAnimate.value =
    scrollTop.value <= lastScrollTop.value && scrollTop.value !== 0

  if (scrollTop.value === 0) {
    affix.value = false
  } else if (scrollTop.value > height.value) {
    affix.value = true
  }

  // For Mobile or negative scrolling
  lastScrollTop.value = scrollTop.value <= 0 ? 0 : scrollTop.value
}

const setAffix = () => {
  if (headerHandler.value.isStickyWithAnimation) {
    document.addEventListener('scroll', affixHandle, { passive: true })
  } else {
    affix.value = false
    document.removeEventListener('scroll', affixHandle)
  }
}

watch(
  () => route.path,
  () => {
    setAffix()
  },
)

watch(affix, (value) => {
  setTemporaryTheme(value ? 'white' : pageTheme.value)
})

onMounted(() => {
  setAffix()
})

const handleClickBurgerMenu = () => {
  setMenuOpen(true)
  trackEvent({ event: 'menu_burger_icon_click', page_type: pageType.value })
}

const handleClickSearchMobile = () => {
  setSearchMobileOpen(true)
  trackEvent({
    event: 'search_icon_click',
    page_type: pageType.value,
  })
}
</script>

<template>
  <header
    :class="[
      'the-header z-fixed',
      heightClass,
      { 'sticky top-0': headerHandler.isStickWithoutAnimation },
    ]"
  >
    <div
      class="flex items-center justify-between px-4 py-5 transition-all lg:px-6"
      :class="[
        heightClass,
        {
          'affix fixed w-full':
            headerHandler.isStickyWithAnimation && affix && scrollTop > 0,
          'bg-white md:border-b md:border-gray-200':
            (headerHandler.isStickyWithAnimation && affix && scrollTop > 0) ||
            headerTheme === 'white',
          'affix-animate': headerHandler.isStickyWithAnimation && affixAnimate,
        },
      ]"
    >
      <div class="flex items-center">
        <TheHeaderMobileBurger
          :line-color="colorIcon"
          class="mr-2 !py-2 !pr-2"
          @update:model-value="handleClickBurgerMenu"
        />
        <TheHeaderLogo />
      </div>

      <ClientOnly v-if="headerSearchHandler.showSearch && !isSmallScreen">
        <SearchInputV2Container next-flow />

        <template #fallback>
          <SearchInputV2ContainerFallback />
        </template>
      </ClientOnly>

      <div>
        <nav>
          <ul class="m-0 flex items-center gap-4">
            <li v-if="nameRoute === 'house-slug'">
              <NuxtLink
                :aria-label="$t('header.search')"
                :to="localePath('search')"
                class="block"
                @click="
                  trackEvent({
                    event: 'search_icon_click',
                    page_type: pageType,
                  })
                "
              >
                <BaseIcon
                  name="search"
                  :class="['icon-transition', colorClassIcon]"
                  :stroke-width="1"
                />
              </NuxtLink>
            </li>
            <li v-if="headerSearchHandler.showSearch" class="md:hidden">
              <button
                type="button"
                class="block"
                @click="handleClickSearchMobile"
              >
                <BaseIcon
                  name="search"
                  :class="['icon-transition', colorClassIcon]"
                  :stroke-width="1"
                />
              </button>
            </li>
            <li>
              <TheHeaderButtonWishlist />
            </li>
            <li>
              <NuxtLink
                v-if="isMobile && authenticated"
                :aria-label="$t('header.account')"
                :to="accountLink"
                class="block"
                data-cy="header-account"
              >
                <BaseIcon
                  name="profileCircle"
                  :class="['icon-transition', colorClassIcon]"
                  :stroke-width="1"
                />
              </NuxtLink>
              <button
                v-else-if="isMobile && !authenticated"
                class="block"
                type="button"
                @click="setSignInChoiceOpen(true)"
              >
                <BaseIcon
                  name="profileCircle"
                  :class="['icon-transition', colorClassIcon]"
                  :stroke-width="1"
                />
              </button>
              <TheHeaderAccountButton v-else />
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </header>
</template>

<style>
.the-header .affix {
  top: calc(-1 * var(--header-height) - 3px);
  transition: top 0.5s ease-in-out;
}
.the-header .affix-animate {
  top: 0;
}
</style>
